import { Card, Nav, Navbar } from "react-bootstrap";

export default function Navigation({ title, linkData }) {
  return (
    <Navbar className="flex-column bg-body-secondary rounded-5">
      <Navbar.Brand>
        <h2>{title}</h2>
      </Navbar.Brand>
      <Nav className="flex-column gap-4">
        {linkData.map(({ title, icon, source }) => (
          <Nav.Item
            key={title}
            className="flex-column  align-items-center bg-light-subtle border border-dark rounded-5"
            style={{ width: "8rem", height: "8rem" }}>
            <Nav.Link
              href={source}
              target={"_blank"}
              className="align-self-center">
              <Card style={{ width: "5rem", height: "5rem", margin: "auto " }}>
                <Card.Img variant="top" src={icon} />
                <Card.Title>{title}</Card.Title>
              </Card>
            </Nav.Link>
          </Nav.Item>
        ))}
      </Nav>
    </Navbar>
  );
}
