import "./App.css";
import { Container, Placeholder, Row } from "react-bootstrap";
import JumboWelcome from "./components/JumboWelcome";
import QuickLinks from "./components/QuickLinks";
import Experiences from "./Experiences";

function App() {
  return (
    <Container fluid className="App m-0">
      <Row as="header" className="name-title ms-5 mb-5 mt-4">
        <h1
          className="display-2 col-8 text-start bg-body-secondary rounded-3"
          style={{ width: "fit-content" }}>
          <Placeholder
            bg="secondary"
            className="align-self-start ms-4"
            style={{ width: "100px", height: "100px", borderRadius: "35%" }}
          />
          <span className="ms-4">Brittney Braxton</span>
        </h1>
      </Row>

      <Row className="vh-100">
        <QuickLinks />
        <JumboWelcome />
      </Row>
      <Row>
        <Experiences />
      </Row>
    </Container>
  );
}

export default App;
