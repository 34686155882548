import { Col, Container } from "react-bootstrap";

export default function JumboWelcome() {
  return (
    <Col
      lg={8}
      className="jumbo-welcome pt-5 px-4 mb-4 bg-body-tertiary rounded-5"
      style={{ height: "fit-content" }}>
      <Container fluid className="mt-2 text-start">
        <h1 className="display-5 fw-bold pb-3">Hello, world!</h1>
        <p className="col-md-10 fs-4">
          I am a passionate software engineer dedicated to crafting web
          applications that prioritize user experience and accessibility above
          all else.
        </p>
        <p className="col-md-10 fs-4">
          With over 10 years of experience in technology, including customer
          service, tech support, and software engineering, I bring a diverse and
          valuable perspective to products.
        </p>

        <p className="col-md-10 fs-4">
          Welcome to my enchanting garden of creativity and innovation. Take a
          moment to savor a cup of tea as you explore the wonders that await you
          here.
        </p>
      </Container>
    </Col>
  );
}
