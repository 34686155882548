import { Col } from "react-bootstrap";
import Navigation from "./Navigation";

export default function QuickLinks() {
  const linkData = [
    {
      title: "Resume",
      icon: "https://placehold.co/600",
      source:
        "https://docs.google.com/document/d/1NQsxceqtiDg6zheYZrBgMgOvEuXnRlxsPxFlqH92A0s/edit?usp=sharing",
    },
    {
      title: "GitHub",
      icon: "https://placehold.co/600",
      source: "https://www.github.com/mintii",
    },
    {
      title: "Blog",
      icon: "https://placehold.co/600",
      source: "https://mintii.bearblog.dev/",
    },
  ];
  return (
    <Col
      className="quick-links"
      style={{ marginLeft: "8rem" }}
      lg={{ order: "last" }}>
      <Navigation title={"Quick Links"} linkData={linkData} />
    </Col>
  );
}
