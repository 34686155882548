import { Card, Col, Container, Row } from "react-bootstrap";

export default function Experiences(params) {
  return (
    <Container>
      <h1>Experiences</h1>
      <p>
        Neat experiences I’ve had at various companies and the products I built
        alongside a brilliant collection of minds.
      </p>
      <Row>
        <Col md={6}>
          <Card>
            <Card.Body>
              I developed an advanced internal search engine that transformed
              drug research at a top pharmaceutical company. Using React,
              TypeScript, GraphQL, Apollo, Neo4J, and Cyprus, I connected data
              into a unified UI, making the process more efficient and
              standardized. Through collaboration with different teams, we
              created a cohesive solution that set a new standard for
              pharmaceutical innovation.
            </Card.Body>
            <Card.Title>Pharmaceuticals</Card.Title>
            <Card.Img
              src="https://placehold.co/60"
              style={{ height: "100px", width: "100px" }}></Card.Img>
          </Card>
        </Col>
        <Col md={6}>
          <Card style={{ width: "25rem" }}>
            <Card.Body className="text-start">
              I developed an advanced internal search engine that transformed
              drug research at a top pharmaceutical company. Using React,
              TypeScript, GraphQL, Apollo, Neo4J, and Cyprus, I connected data
              into a unified UI, making the process more efficient and
              standardized. Through collaboration with different teams, we
              created a cohesive solution that set a new standard for
              pharmaceutical innovation.
            </Card.Body>
            <Row>
              <Col xs={9} className="align-content-center">
                <Card.Title>Pharmaceuticals</Card.Title>
              </Col>
              <Col xs={3}>
                <Card.Img
                  src="https://placehold.co/60"
                  style={{ height: "5rem", width: "5rem" }}></Card.Img>
              </Col>
            </Row>
          </Card>
        </Col>
      </Row>
    </Container>
  );
}
